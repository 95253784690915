import axios_config from "./config";
var ethicalPortal = {};

ethicalPortal.sendQuote = (quote) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(`/ethical-portal/v2/quote/`, quote)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.sendComplaint = (company, email_ratification = "", complaint) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(
        `/ethical-portal/v2/complaint/${email_ratification}`,
        complaint
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.sendComplaintFiles = (company, fileData) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(
        `/ethical-portal/v2/complaint/${company}/file`,
        fileData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.updateCompanyLogo = ( imagelogo) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(`/ethical-portal/v2/documents/logo`, imagelogo)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.updateCompanyDocumnet = (company, document) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(`/ethical-portal/v2/companies/${company}/document`, document, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getCompanies = () => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/companies/company`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getCompanyByName = (companyName, credentials = false) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/companies/`, {
        withCredentials: credentials,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getCompanyLogo = ( credentials = false) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/documents/logo?base64=true`, {
        withCredentials: credentials,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getCompanyStatistics = (companyName, credentials = false) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/companies/${companyName}/statistics`, {
        withCredentials: credentials,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getCompanyForm = (companyName, credentials = false) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/companies/complaint-form`, {
        withCredentials: credentials,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.trackingComplaint = (trackingNro) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/complaint/${trackingNro}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.updateCompanyInfo = (data) => {
  return new Promise((resolve, reject) => {
    axios_config
      .put(`/ethical-portal/v2/companies/`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.updateCompanyForm = (companyName, form) => {
  return new Promise((resolve, reject) => {
    axios_config
      .put(`/ethical-portal/v2/companies/${companyName}/complaint-form`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.getComplaintComments = (uuid) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/complaint/${uuid}/comments`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
ethicalPortal.sendComplaintComment = (uuid, comment) => {
  return new Promise((resolve, reject) => {
    axios_config
      .post(`/ethical-portal/v2/complaint/${uuid}/comments`, {
        comment: comment
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

ethicalPortal.downloadDocument = (docname) => {
  return new Promise((resolve, reject) => {
    axios_config
      .get(`/ethical-portal/v2/documents/${docname}/download`, null, {
        resposeType: "blob",
      })
      .then((res) => {
        const doc = new Blob([res.data]);
        const url = window.URL.createObjectURL(doc);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", docname);
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default ethicalPortal;
