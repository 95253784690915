import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
//modules
import global from "./global";
// Load Vuex

Vue.use(Vuex);
const vuexLocalStorage = new VuexPersist({
  key: "portal-etico",
  storage: window.localStorage,
});
// Create store
export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: { global },
});
