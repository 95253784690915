<template lang="pug">
#dashboard-layout
  Header
  slot
  Footer
</template>
<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  name: "dashboard-layout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "dashboard-layout",
    };
  },
};
</script>
