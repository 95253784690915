import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import 'animate.css';
Vue.component("v-select", vSelect);

import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "./assets/scss/theme.scss";
import "./assets/scss/styles.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
Vue.use(Buefy, {
  defaultIconPack: "fas",
});

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueMoment from "vue-moment";
Vue.use(VueMoment);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

//-layout
import loginLayout from "./layouts/login";
import dashboardLayout from "./layouts/dashboard";
import websiteLayout from "./layouts/website";
import BlanckLayout from "./layouts/blank";
import LogoIcon from "./components/LogoIcon";
Vue.component("blank-layout", BlanckLayout);
Vue.component("website-layout", websiteLayout);
Vue.component("login-layout", loginLayout);
Vue.component("dashboard-layout", dashboardLayout);
Vue.component("logo-icon", LogoIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
