<template lang="pug">
#TheFooter
    .container.p-5
      .columns
        .column
          p.m-3
            strong Chile: Andrés de Fuenzalida 22 of 602, Providencia, Santiago
          p.m-3
            strong USA: 848 Brickell Ave #1210 - Miami - FL33131
          p.m-3
            strong +569 96668379
          p.m-3
            strong info@ceptinel.com
          p.m-3
            b-tooltip(label="Ir a Linkedin", position="is-top")
              a(
                href="https://www.linkedin.com/showcase/portal-%C3%A9tico",
                target="_blank"
              )
                img(src="@/assets/linkedin.png", style="height: 20px")
                | &nbsp;
                strong Linkedin - Portal ético
  
        .column
          .flex-imgs
            img(src="@/assets/logo.png", style="height: 50px")
            b-tooltip(label="Ir a az.cl", position="is-top")
              a(href="https://www.az.cl", target="_blank")
                img(src="@/assets/az.png", style="height: 50px")
            b-tooltip(label="Ir a ceptinel.cl", position="is-top")
              a(href="https://www.ceptinel.com", target="_blank")
                img(src="@/assets/imagotipo.png", style="height: 50px")
</template>
<script>
export default {
  name: "TheFooter",
};
</script>
<style>
.flex-imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
.flex-imgs > * {
  flex-grow: 1;
}
.flex-imgs img {
  object-fit: contain;
}
</style>
