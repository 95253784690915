<template lang="pug">
#Header
  b-navbar
    template(slot='brand')
      b-navbar-item
        router-link(to="config")
          img(src="@/assets/imagotipo.png" style="max-height:inherit;height:50px;display:block;padding-left: 2rem;")
    template(slot='end')
      b-navbar-item( tag="router-link" :to="{ path: '/config' }")
        a Inicio
      b-navbar-item( v-show="$store.state.global.userLoged" tag="router-link" :to="{ path: '/account' }")
        a Mi cuenta
      b-navbar-item( v-show="$store.state.global.userLoged" tag="router-link" :to="{ path: '/form' }")
        a Formulario de denuncia
      b-navbar-item
        button.button.is-primary(v-on:click="logOut") Salir
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      title: "Header",
    };
  },
  methods: {
    logOut() {
      this.$store.commit("logOut");
      this.$router.replace("Login");
    },
  },
};
</script>
