<template lang="pug">
main
  component(:is="layout")
    router-view
</template>
<script>
const defaultLayout = "website";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>
