import axios from "axios";

const axios_config = axios.create({
  baseURL: `/risk-backend/rest/`,
});

// // // Add a request interceptor
// axios_config.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axios_config.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axios_config;
