<template>
  <span
    :class="`logo-icon-svg ${
      animate ? 'animate__animated animate__infinite animate__wobble' : ''
    }`"
    :style="{ height }"
  >
    <svg
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115.95 96.27"
    >
      <path
        :fill="white ? '#fff' : '#1d1d1b'"
        class="cls-2"
        d="M102.43,87.43c-6.63-.53-12.92-1.04-19.21-1.54-2.11-.17-4.22-.34-6.33-.54-.5-.05-.73,.09-.85,.56-1.56,6.26-6.15,8.88-12.01,9.91-4.42,.78-8.84,.46-13.23-.33-3.07-.55-6.21-.42-9.26-1.1-3.22-.72-6.32-1.67-9.06-3.57-3.11-2.16-5.22-5.01-5.91-8.79-.11-.59-.36-.78-.94-.82-4.18-.31-8.36-.66-12.54-.97-1.99-.15-2.75-.83-3.08-2.81-1.16-7-2.33-14-3.51-21-.3-1.81,.05-2.65,1.6-3.62C33.08,37.25,58.06,21.68,83.03,6.12c2.11-1.32,3.69-.68,4.25,1.74,5.18,22.48,10.36,44.96,15.54,67.44,.68,2.95,1.37,5.9,2.01,8.86,.41,1.9-.84,3.34-2.4,3.28Zm-7.91-6.02c1.53,.31,3.08,.27,4.7,.44-.06-.28-.09-.47-.13-.65-5.18-22.55-10.37-45.11-15.56-67.66-.33-1.42-.34-1.42-1.61-.63C59.38,26.96,36.85,41.01,14.32,55.06q-2.87,1.79-2.14,5.1c.73,4.51,1.49,9.02,2.17,13.54,.17,1.13,.74,1.55,1.81,1.51,.64-.02,1.28,.01,1.91,.07,4.48,.37,8.96,.77,13.45,1.13,3.41,.28,6.83,.5,10.25,.78,4.44,.36,8.88,.77,13.33,1.13,3.45,.28,6.91,.5,10.37,.77,4.76,.38,9.52,.77,14.28,1.17,2.42,.2,4.83,.45,7.25,.64,2.5,.19,5,.34,7.51,.5Zm-62.93,.32c.61,1.95,1.78,3.32,3.29,4.43,1.86,1.36,3.96,2.29,6.2,2.69,5.96,1.06,11.97,1.84,18.03,2.09,2.63,.11,5.26-.25,7.73-1.32,1.74-.75,3.12-1.87,3.99-3.61,.46-.93,.25-1.14-.75-1.22-5.57-.4-11.14-.87-16.71-1.32-7.2-.58-14.39-1.15-21.77-1.75Z"
      />
      <path
        :fill="white ? '#fff' : '#1d1d1b'"
        class="cls-2"
        d="M95.54,0c1.14,.03,2.04,.81,2.35,2.19,.95,4.2,1.86,8.4,2.78,12.61,4.76,21.72,9.51,43.44,14.27,65.17,.32,1.44,.63,2.88,.94,4.32,.34,1.63-.47,3.06-1.88,3.36-1.42,.3-2.59-.64-2.98-2.41-1.51-6.89-3.03-13.78-4.53-20.67-4.44-20.32-8.89-40.64-13.33-60.97-.43-1.98,.67-3.65,2.39-3.6Z"
      />
      <path
        :fill="white ? '#fff' : '#1d1d1b'"
        class="cls-2"
        d="M8.12,78.4c-.01,1.5-.98,2.64-2.26,2.76-1.21,.12-2.33-.75-2.58-2.17-.82-4.63-1.58-9.28-2.36-13.92-.29-1.69-.56-3.38-.85-5.07-.3-1.72,.53-3.12,2.02-3.4,1.34-.25,2.57,.76,2.85,2.42,1.06,6.25,2.1,12.51,3.14,18.76,.04,.24,.04,.48,.05,.62Z"
      />
      <path
        :fill="white ? '#fff' : '#662483'"
        class="cls-1"
        d="M94.52,81.41c-2.5-.17-5.01-.31-7.51-.5-2.42-.19-4.84-.44-7.25-.64-4.76-.4-9.52-.8-14.28-1.17-3.45-.27-6.91-.49-10.37-.77-4.44-.36-8.88-.77-13.33-1.13-3.41-.28-6.83-.5-10.25-.78-4.48-.36-8.96-.76-13.45-1.13-.64-.05-1.28-.09-1.91-.07-1.08,.03-1.64-.38-1.81-1.51-.69-4.52-1.44-9.03-2.17-13.54,.47-.2,.96-.37,1.42-.6,21.36-10.79,42.72-21.58,64.05-32.41,1.02-.52,1.25-.32,1.54,.7,3.29,11.58,6.64,23.14,9.95,34.7,1.8,6.28,3.57,12.57,5.36,18.86Z"
      />
    </svg>
  </span>
</template>
<script>
export default {
  name: "LogoIcon",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "20px",
    },
    white: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.logo-icon-svg {
  vertical-align: middle;
  margin: 0 0.3rem;
  display: inline-flex;
}
</style>
