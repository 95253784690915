<template lang="pug">
b-navbar#TheNavbar
  template(#brand)
    template(v-if="logo")
      img(
        @click="toHome()",
        :src="logo",
        alt="portaletico"
        style="height: 55px; margin-left:1rem",  
      )
    template(v-else)
      img(
        @click="toHome()",
        src="@/assets/logo.png",
        style="height: 55px; margin-left:1rem",
        alt="portaletico"
      )

  template(#end) 
    b-navbar-item(tag="div" v-if="$route.name != 'company-init'") 
      b-button(type="is-secondary", tag="router-link", to="/company/init")
        logo-icon(white, aniamte)
        span iniciar denuncia
    b-navbar-item(tag="div") 
      b-button(type="is-primary", tag="router-link", to="/tracking")
        b-icon(pack="fas", icon="file")
        | &nbsp;&nbsp;&nbsp;
        | Estado de mi denuncia
</template>
<script>
import api from "@/axios/ethicalPortal";
export default {
  name: "Header",
  data() {
    return {
      title: "Header",
      logo: null,
    };
  },
  mounted() {
    this.getLogo();
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    async getLogo() {
      const logo = await api.getCompanyLogo(this.companyName, false);
      if (logo) this.logo = logo.data;
    },
  },
};
</script>
