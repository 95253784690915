<template lang="pug">
#blank-layout
  slot
</template>
<script>
export default {
  name: "blank-layout",
  data() {
    return {
      title: "blank-layout",
    };
  },
};
</script>
