import Vue from "vue";
import VueRouter from "vue-router";

let route = (route) => {
  return () => import(`@/views/${route}`);
};

Vue.use(VueRouter);

const routes = [
  //- PUBLIC WEBSITE
  {
    path: "/",
    name: "Home",
    // component: route("website/home"),
    component: route("website/companies/detail"),
    meta: {
      layout: "website",
    },
  },
  {
    path: "/init",
    name: "init",
    component: route("website/home"),
    meta: {
      layout: "website",
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: route("website/companies"),
    meta: {
      layout: "website",
    },
  },
  {
    path: "/company",
    name: "company-detail",
    component: route("website/companies/detail"),
    props: true,
    meta: {
      layout: "website",
    },
  },
  {
    path: "/company/init",
    name: "company-init",
    component: route("website/complaint/init"),
    props: true,
    meta: {
      layout: "website",
    },
  },
  {
    path: "/company",
    name: "company",
    component: route("website/company"),
    props: true,
    meta: {
      layout: "blank",
    },
  },
  // tracking
  {
    path: "/tracking",
    name: "tracking-init",
    component: route("website/tracking"),
    props: true,
    meta: {
      layout: "website",
    },
  },
  //- login
  {
    path: "/login",
    name: "login",
    component: route("login/"),
    meta: {
      layout: "login",
    },
  },
  //- dashboard
  {
    path: "/config",
    name: "dashboard",
    component: route("dashboard/account"),
    meta: {
      layout: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: route("dashboard/account"),
    meta: {
      layout: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/form",
    name: "form",
    component: route("dashboard/form"),
    meta: {
      layout: "dashboard",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

//router
import store from "../store";

router.beforeEach((to, from, next) => {

  document.title = `${to.name}`;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.global.userLoged) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
