const state = {
  userLoged: null,
  companySelected: null,
};
const getters = {
  getCompanySelected: (state) => state.companySelected,
  getUserLoged: (state) => state.userLoged,
};
const mutations = {
  loginUser(state, user) {
    state.userLoged = user;
  },
  setCompnayData(state, data) {
    if (data && data.complaint_form && data.complaint_form.length > 0) {
      data.complaint_form = JSON.parse(data.complaint_form)
    }
    if (data && data.documents && data.documents.length > 0) {
      data.documents = data.documents.map(e => { return { ...e, url: e.url.split('.com/')[1] } })
    }
    // data.more_info = JSON.parse(data.more_info)
    state.companySelected = data;
  },
  removeCompnayData(state) {
    state.companySelected = null;
  },
  logOut(state) {
    state.userLoged = null;
  },
};
const actions = {
  removeCompnayData({ commit }) {
    commit('removeCompnayData');
  },
  logOut({ commit }) {
    commit('logOut');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
