<template lang="pug">
#login-layout
  slot
</template>
<script>
export default {
  name: "login-layout",
  data() {
    return {
      title: "login-layout",
    };
  },
};
</script>
