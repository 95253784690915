<template lang="pug">
#website-layout
  Header
  slot
  Footer
</template>
<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  name: "website-layout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "website-layout",
    };
  },
};
</script>
