<template lang="pug">
footer#Footer 
  hr   
  .content.has-text-centered
    p
      | Powered by
      strong CEPTINEL
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      title: "Footer",
    };
  },
};
</script>
